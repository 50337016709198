// import React from 'react';
// import HrisMain from '../../components/HrisIntegration';

// function HrisContainer() {
//   return (
//     <React.Fragment>
//       <HrisMain/>
//     </React.Fragment>
//   )
// }

// export default HrisContainer

/* eslint-disable no-console,no-undef, camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import HrisMain from '../../components/HrisIntegration';
import { Route, Switch } from 'react-router-dom';
import SsoSuccess from '../../components/HrisIntegration/SsoSucccess';

class HrisContainer extends Component {

  HrisMain = () => (
    <HrisMain companyInfo={this.props.companyInfo} history={this.props.history}/>
  );

  connectionSsoSuccess = () => (
    <SsoSuccess companyInfo={this.props.companyInfo} history={this.props.history}/>
  );

  render() {
    return (
      <Switch>
        <Route path="/company/hris-integration" render={this.HrisMain} exact={true} />
        <Route path="/company/hris-integration/success" render={this.connectionSsoSuccess} exact={true} />
      </Switch>
    )
  }
}

HrisContainer.propTypes = {
  history: PropTypes.object.isRequired,
  companyInfo: PropTypes.object
};

export default connect(null, null)(HrisContainer);

